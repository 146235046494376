export const canDismiss = async (_data: any, role?: string) =>
  role !== 'gesture';

export function getPresentingElement() {
  const presentingElement =
    document.querySelector('ion-router-outlet') ?? undefined;
  return presentingElement;
}

export function lines(
  arr: any[] | undefined,
  index: number,
  lastItem: HTMLIonItemElement['lines'] = 'full'
): HTMLIonItemElement['lines'] {
  return index + 1 === arr?.length ? lastItem : undefined;
}
